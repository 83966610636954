import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer" id="footer">
      <div>Developed by Anna Indyukova</div>
      <div>2024</div>
    </footer>
  );
};
export default Footer;
